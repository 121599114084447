import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "m-slip"
};
var _hoisted_2 = {
  key: 0,
  class: "m-slip__prepend"
};
var _hoisted_3 = {
  class: "m-slip__main"
};
var _hoisted_4 = {
  key: 1,
  class: "m-slip__append"
};
import { ref, reactive, onMounted, nextTick } from "vue";
export default {
  __name: 'MSlip',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.$slots['prepend'] ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "prepend")])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default")]), _ctx.$slots['append'] ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "append")])) : _createCommentVNode("", true)]);
    };
  }
};